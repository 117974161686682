import {
  ASSETS_SECTION,
  BUTTON_INPUT_TYPE,
  BUTTON_MULTI_SELECT_INPUT_TYPE,
  DROPDOWN_INPUT,
  GOALS_SECTION,
  LIABILITIES_SECTION,
  NUMERIC_INPUT,
  TEXT_INPUT,
  SERVICES_SECTION,
  DATE_INPUT,
} from './common/constants';
import { OnboardingStateNode, onboardingStateGraph, answersObject } from './common/types';

// TYPES

// GRAPH OBJECT
export const ONBOARDING_STATE_GRAPH: onboardingStateGraph = {
  GOALS: {
    levels: 7,
    nodes: {
      LINKEDIN_URL: {
        level: 1,
        displayTemplate: null,
        node: 'LINKEDIN_URL',
        nodeQuestions: [
          {
            questionId: 'LINKEDIN_URL',
            section: GOALS_SECTION,
            question: 'Can you share a link to your LinkedIn Profile?',
            questionOptionType: TEXT_INPUT,
            questionOption: [],
            selectedOption: [],
          },
        ],
      },
      RELATIONSHIP_STATUS: {
        level: 1,
        displayTemplate: 'I am *value_0*',
        node: 'RELATIONSHIP_STATUS',
        nodeQuestions: [
          {
            questionId: 'RELATIONSHIP_STATUS',
            section: GOALS_SECTION,
            question: 'What is your current marital status',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['Single', 'Married', 'Separated', 'Widow(er)'],
            selectedOption: [],
          },
        ],
      },
      MARRIAGE_PLAN: {
        level: 2,
        displayTemplate: null,
        node: 'MARRIAGE_PLAN',
        nodeQuestions: [
          {
            questionId: 'MARRIAGE_PLAN',

            section: GOALS_SECTION,
            question: 'Are you planning to get married',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['Yes', 'No'],
            selectedOption: [],
          },
        ],
      },
      MARRIAGE_DATE_PLANNING: {
        level: 3,
        displayTemplate: null,
        node: 'MARRIAGE_DATE_PLANNING',
        nodeQuestions: [
          {
            questionId: 'MARRIAGE_DATE_PLANNING',
            section: GOALS_SECTION,
            question: 'Approximately when do plan to get married?',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['Less than a year', 'After 1 year', 'After 2 years', 'After 3 years'],
            selectedOption: [],
          },
        ],
      },
      MARRIAGE_AMOUNT: {
        level: 4,
        displayTemplate: null,
        node: 'MARRIAGE_AMOUNT',
        nodeQuestions: [
          {
            questionId: 'MARRIAGE_AMOUNT',
            section: GOALS_SECTION,
            question: 'How much would you keep aside for your marriage?',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['Less than 10 L', '10 - 15 L', '15 - 25 L ', 'More than 50 L'],
            selectedOption: [],
          },
        ],
      },

      CHILDREN_STATUS: {
        level: 2,
        displayTemplate: null,
        node: 'CHILDREN_STATUS',
        nodeQuestions: [
          {
            questionId: 'CHILDREN_STATUS',

            section: GOALS_SECTION,
            question: 'Do you have kids ?',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['Yes', 'No'],
            selectedOption: [],
          },
        ],
      },
      CHILDREN_NUMBER: {
        level: 3,
        displayTemplate: null,
        node: 'CHILDREN_NUMBER',
        nodeQuestions: [
          {
            questionId: 'CHILDREN_NUMBER',

            section: GOALS_SECTION,
            question: 'How many kids do you have ?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['1', '2', '3', '4', '5', '6', '7', '8'],
            selectedOption: [],
          },
        ],
      },
      CHILDREN_EDUCATION_AMOUNT: {
        level: 4,
        displayTemplate: null,
        node: 'CHILDREN_EDUCATION_AMOUNT',
        nodeQuestions: [
          {
            questionId: 'MARRIAGE_AMOUNT',
            section: GOALS_SECTION,
            question: 'How much would you keep aside for their education?',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['About 10L', '10L - 25L', '25L - 50L ', '50L - 1Cr', '1Cr - 1.5Cr', 'More than 1.5Cr'],
            selectedOption: [],
          },
        ],
      },
      OTHER_GOALS: {
        level: 6,
        displayTemplate: null,
        node: 'OTHER_GOALS',
        nodeQuestions: [
          {
            questionId: 'OTHER_GOALS',
            section: GOALS_SECTION,
            question: 'What among these other goals would you like to invest for?',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['Car', 'House', 'Retirement', 'Financial Freedom', 'Vacation', 'Emergency Corpus', 'Done'],
            selectedOption: [],
          },
        ],
      },
      CAR_DETAILS: {
        level: 5,
        displayTemplate: 'I want to save *value_1* *value_0* to buy a Car',
        node: 'CAR_DETAILS',
        nodeQuestions: [
          {
            questionId: 'CAR_PURCHASE_DURATION',
            section: 'goals',
            question: 'By when do you wish to buy a car?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['2025', '2026', '2027', '2028', '2029', '2030'],
            selectedOption: [],
          },
          {
            questionId: 'CAR_PURCHASE_AMOUNT',
            section: 'goals',
            question: 'How much do you intend to save for this goal?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['About 10L', '10L - 20L', '20L - 30L ', '30L - 50L', 'More than 50 L'],
            selectedOption: [],
          },
        ],
      },
      HOUSE_DETAILS: {
        level: 6,
        displayTemplate: 'I want to save *value_1* in *value_0* to buy a House',
        node: 'HOUSE_DETAILS',
        nodeQuestions: [
          {
            questionId: 'HOUSE_PURCHASE_DURATION',
            section: GOALS_SECTION,
            question: 'By when do you wish to buy a house?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035', '2036'],
            selectedOption: [],
          },
          {
            questionId: 'HOUSE_PURCHASE_AMOUNT',
            section: GOALS_SECTION,
            question: 'How much do you intend to save for buying a House?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['Less than 30L', '30L - 50L', '50 - 75 L', '75 - 1Cr', '1Cr - 1.5Cr', '1.5Cr - 2Cr', '2Cr - 3Cr', 'More than 3Cr'],
            selectedOption: [],
          },
        ],
      },
      RETIREMENT_DETAILS: {
        level: 6,
        displayTemplate: 'I want to save *value_1* in *value_0* for Retirement',
        node: 'RETIREMENT_DETAILS',
        nodeQuestions: [
          {
            questionId: 'RETIREMENT_DURATION',
            section: GOALS_SECTION,
            question: 'In how many years do you plan to retire?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['within 5 years', '5 - 10 years', '10 - 15 years', '15 - 20 years', '20 - 30 years'],
            selectedOption: [],
          },
          {
            questionId: 'RETIREMENT_PURCHASE_AMOUNT',
            section: GOALS_SECTION,
            question: 'How much do you intend to save for Retirement?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['About 50L', '50L - 1Cr', '1 - 2Cr', 'More than 2 CR'],
            selectedOption: [],
          },
        ],
      },
      FIN_FREEDOM_DETAILS: {
        level: 7,
        displayTemplate: 'I want to save *value_1* in *value_0*',
        node: 'FIN_FREEDOM_DETAILS',
        nodeQuestions: [
          {
            questionId: 'FIN_FREEDOM_DURATION',
            section: GOALS_SECTION,
            question: 'What is the minimum time you want to achieve your FIRE corpus?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['a year', '1 - 5 years', '5 - 10 years', '10 - 20 years', '20 - 30 years'],
            selectedOption: [],
          },
          {
            questionId: 'FIN_FREEDOM_AMOUNT',
            section: GOALS_SECTION,
            question: 'What is FIRE number?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['About 50L', '50L - 1Cr', '1 - 2Cr', 'More than 2 Cr'],
            selectedOption: [],
          },
        ],
      },
      EMERGENCY_CORPUS_DETAILS: {
        level: 7,
        displayTemplate: 'I want to save *value_0* as an Emergency Fund',
        node: 'EMERGENCY_CORPUS_DETAILS',
        nodeQuestions: [
          {
            questionId: 'EMERGENCY_CORPUS_AMOUNT',
            section: GOALS_SECTION,
            question: 'How much do you wish to set aside as your Emergency Corpus?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['About 1L', '1L - 3L', '3L - 5L', '5L - 10L', 'More than 10L'],
            selectedOption: [],
          },
        ],
      },
      VACATION_DETAILS: {
        level: 5,
        displayTemplate: 'I want to save *value_2* by *value_0* *value_1* for a Vacation',
        node: 'VACATION_DETAILS',
        nodeQuestions: [
          {
            questionId: 'VACATION_MONTH',
            section: GOALS_SECTION,
            question: 'By when do you plan to take the Vacation (month)?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            selectedOption: [],
          },
          {
            questionId: 'VACATION_YEAR',
            section: GOALS_SECTION,
            question: 'By when do you plan to take the Vacation (year)?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['2025', '2026', '2027', '2028', '2029', '2030'],
            selectedOption: [],
          },
          {
            questionId: 'VACATION_AMOUNT',
            section: GOALS_SECTION,
            question: 'How much do you intend to save for your vacation?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['About 50k', '50k - 1L', '1L - 2L', '2L - 3L', '3L - 5L', 'More than 5L'],
            selectedOption: [],
          },
        ],
      },
      GOALS_THANKYOU: {
        level: 6,
        displayTemplate: null,
        node: 'GOALS_THANKYOU',
        nodeQuestions: [
          {
            questionId: 'GOALS_THANKYOU',
            section: GOALS_SECTION,
            question: 'Thank you for sharing your Goals with us. This will help us plan your investments better.',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['Thank You'],
            selectedOption: [],
          },
        ],
      },
      
    },
  },
  LIABILITIES: {
    levels: 5,
    nodes: {
      EMI_STATUS: {
        level: 1,
        displayTemplate: null,
        node: 'EMI_STATUS',
        nodeQuestions: [
          {
            questionId: 'EMI_STATUS',
            section: LIABILITIES_SECTION,
            question: 'Are you currently servicing any EMIs?',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['Yes', 'No'],
            selectedOption: [],
          },
        ],
      },
      LOAN_TYPES: {
        level: 2,
        displayTemplate: null,
        node: 'LOAN_TYPES',
        nodeQuestions: [
          {
            questionId: 'LOAN_TYPES',
            section: LIABILITIES_SECTION,
            question: 'Which loans are currently active?',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['Home Loan', 'Vehicle Loan', 'Education Loan', 'Personal Loan', 'Done'],
            selectedOption: [],
          },
        ],
      },
      HOME_LOAN_DETAILS: {
        level: 2,
        displayTemplate:
          'I have a home loan of *value_0* lacs with interest *value_1*% for a period of *value_2* years. Started repaying the loan in *value_3* *value_4*',
        node: 'HOME_LOAN_DETAILS',
        nodeQuestions: [
          {
            questionId: 'HOME_LOAN_AMOUNT',
            section: LIABILITIES_SECTION,
            question: 'What is the home loan amount',
            questionOptionType: NUMERIC_INPUT,
            questionOption: [],
            selectedOption: [],
          },
          {
            questionId: 'HOME_LOAN_INTEREST',
            section: LIABILITIES_SECTION,
            question: 'Rate of interest (in percent %) ',
            questionOptionType: NUMERIC_INPUT,
            questionOption: [],
            selectedOption: [],
          },
          {
            questionId: 'HOME_LOAN_DURATION',
            section: LIABILITIES_SECTION,
            question: 'What is the loan tenure (in years) ',
            questionOptionType: NUMERIC_INPUT,
            questionOption: [],
            selectedOption: [],
          },
          {
            questionId: 'HOME_LOAN_START_MONTH',
            section: LIABILITIES_SECTION,
            question: 'When did you start repaying the loan (month)?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            selectedOption: [],
          },
          {
            questionId: 'HOME_LOAN_START_YEAR',
            section: LIABILITIES_SECTION,
            question: 'When did you start repaying the loan (year)?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025'],
            selectedOption: [],
          },
        ],
      },
      VEHICLE_LOAN_DETAILS: {
        level: 3,
        displayTemplate:
          'I have a vehicle loan of *value_0* lacs with interest *value_1*% for a period of *value_2* years. Started repaying the loan in *value_3* *value_4*',
        node: 'VEHICLE_LOAN_DETAILS',
        nodeQuestions: [
          {
            questionId: 'VEHICLE_LOAN_AMOUNT',
            section: LIABILITIES_SECTION,
            question: 'What is the vehicle loan amount',
            questionOptionType: NUMERIC_INPUT,
            questionOption: [],
            selectedOption: [],
          },
          {
            questionId: 'VEHICLE_LOAN_INTEREST',
            section: LIABILITIES_SECTION,
            question: 'Rate of interest (in percent %) ',
            questionOptionType: NUMERIC_INPUT,
            questionOption: [],
            selectedOption: [],
          },
          {
            questionId: 'VEHICLE_LOAN_DURATION',
            section: LIABILITIES_SECTION,
            question: 'What is the loan tenure (in years) ',
            questionOptionType: NUMERIC_INPUT,
            questionOption: [],
            selectedOption: [],
          },
          {
            questionId: 'VEHICLE_LOAN_START_MONTH',
            section: LIABILITIES_SECTION,
            question: 'When did you start repaying the loan (month)?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            selectedOption: [],
          },
          {
            questionId: 'VEHICLE_LOAN_START_YEAR',
            section: LIABILITIES_SECTION,
            question: 'When did you start repaying the loan (year)?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025'],
            selectedOption: [],
          },
        ],
      },
      EDUCATION_LOAN_DETAILS: {
        level: 4,
        displayTemplate:
          'I have a education loan of *value_0* lacs with interest *value_1*% for a period of *value_2* years. Started repaying the loan in *value_3* *value_4*',
        node: 'EDUCATION_LOAN_DETAILS',
        nodeQuestions: [
          {
            questionId: 'EDUCATION_LOAN_AMOUNT',
            section: LIABILITIES_SECTION,
            question: 'What is the education loan amount',
            questionOptionType: NUMERIC_INPUT,
            questionOption: [],
            selectedOption: [],
          },
          {
            questionId: 'EDUCATION_LOAN_INTEREST',
            section: LIABILITIES_SECTION,
            question: 'Rate of interest (in percent %) ',
            questionOptionType: NUMERIC_INPUT,
            questionOption: [],
            selectedOption: [],
          },
          {
            questionId: 'EDUCATION_LOAN_DURATION',
            section: LIABILITIES_SECTION,
            question: 'What is the loan tenure (in years) ',
            questionOptionType: NUMERIC_INPUT,
            questionOption: [],
            selectedOption: [],
          },
          {
            questionId: 'EDUCATION_LOAN_START_MONTH',
            section: LIABILITIES_SECTION,
            question: 'When did you start repaying the loan (month)?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            selectedOption: [],
          },
          {
            questionId: 'EDUCATION_LOAN_START_YEAR',
            section: LIABILITIES_SECTION,
            question: 'When did you start repaying the loan (year)?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025'],
            selectedOption: [],
          },
        ],
      },
      PERSONAL_LOAN_DETAILS: {
        level: 5,
        displayTemplate:
          'I have a personal loan of *value_0* lacs with interest *value_1*% for a period of *value_2* years. Started repaying the loan in *value_3* *value_4*',
        node: 'PERSONAL_LOAN_DETAILS',
        nodeQuestions: [
          {
            questionId: 'PERSONAL_LOAN_AMOUNT',
            section: LIABILITIES_SECTION,
            question: 'What is the personal loan amount',
            questionOptionType: NUMERIC_INPUT,
            questionOption: [],
            selectedOption: [],
          },
          {
            questionId: 'PERSONAL_LOAN_INTEREST',
            section: LIABILITIES_SECTION,
            question: 'Rate of interest (in percent %) ',
            questionOptionType: NUMERIC_INPUT,
            questionOption: [],
            selectedOption: [],
          },
          {
            questionId: 'PERSONAL_LOAN_DURATION',
            section: LIABILITIES_SECTION,
            question: 'What is the loan tenure (in years) ',
            questionOptionType: NUMERIC_INPUT,
            questionOption: [],
            selectedOption: [],
          },
          {
            questionId: 'PERSONAL_LOAN_START_MONTH',
            section: LIABILITIES_SECTION,
            question: 'When did you start repaying the loan (month)?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            selectedOption: [],
          },
          {
            questionId: 'PERSONAL_LOAN_START_YEAR',
            section: LIABILITIES_SECTION,
            question: 'When did you start repaying the loan (year)?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025'],
            selectedOption: [],
          },
        ],
      },
      LOAN_TYPES_THANKYOU: {
        level: 6,
        displayTemplate: null,
        node: 'LOAN_TYPES_THANKYOU',
        nodeQuestions: [
          {
            questionId: 'LOAN_TYPES_THANKYOU',
            section: LIABILITIES_SECTION,
            question: 'Thank you for sharing your EMI details with us. This will help us plan your investments better.',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['Thank You'],
            selectedOption: [],
          },
        ],
      },
    },
  },
  ASSETS: {
    levels: 4,
    nodes: {
      INVESTMENT_STATUS: {
        level: 1,
        displayTemplate: null,
        node: 'INVESTMENT_STATUS',
        nodeQuestions: [
          {
            questionId: 'INVESTMENT_STATUS',
            section: ASSETS_SECTION,
            question: 'Do you have any other investments?',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['Yes', 'No'],
            selectedOption: [],
          },
        ],
      },
      INVESTMENT_TYPES: {
        level: 2,
        displayTemplate: null,
        node: 'INVESTMENT_TYPES',
        nodeQuestions: [
          {
            questionId: 'INVESTMENT_TYPES',
            section: ASSETS_SECTION,
            question: 'What other investments do you currently have',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['Stocks', 'Mutual Funds', 'PPF', 'PF', 'FDs', 'Real Estate', 'Done'],
            selectedOption: [],
          },
        ],
      },
      STOCKS_INVESTMENT: {
        level: 3,
        displayTemplate: 'My Equity Portfolio is worth *value_0*',
        node: 'STOCKS_INVESTMENT',
        nodeQuestions: [
          {
            questionId: 'STOCKS_INVESTMENT',
            section: ASSETS_SECTION,
            question: 'What is the current approximate value of your Equity Portfolio?',
            questionOptionType: TEXT_INPUT,
            questionOption: [],
            selectedOption: [],
          },
        ],
      },
      MUTUALFUNDS_INVESTMENT: {
        level: 3,
        displayTemplate: 'My Mutual Fund Portfolio is worth *value_0*',
        node: 'MUTUALFUNDS_INVESTMENT',
        nodeQuestions: [
          {
            questionId: 'MUTUALFUNDS_INVESTMENT',
            section: ASSETS_SECTION,
            question: 'What is the current approximate value of your Mutual Fund Portfolio?',
            questionOptionType: TEXT_INPUT,
            questionOption: [],
            selectedOption: [],
          },
        ],
      },
      PPF_INVESTMENT: {
        level: 3,
        displayTemplate: 'I have *value_0* in my PPF account',
        node: 'PPF_INVESTMENT',
        nodeQuestions: [
          {
            questionId: 'PPF_INVESTMENT',
            section: ASSETS_SECTION,
            question: 'What is the current approximate value of your PPF investments?',
            questionOptionType: TEXT_INPUT,
            questionOption: [],
            selectedOption: [],
          },
        ],
      },
      PF_INVESTMENT: {
        level: 3,
        displayTemplate: 'I have *value_0* in my PF account',
        node: 'PF_INVESTMENT',
        nodeQuestions: [
          {
            questionId: 'PF_INVESTMENT',
            section: ASSETS_SECTION,
            question: 'What is the current approximate value in your PF account?',
            questionOptionType: TEXT_INPUT,
            questionOption: [],
            selectedOption: [],
          },
        ],
      },
      FD_INVESTMENT: {
        level: 3,
        displayTemplate: 'I have *value_0* invested in Fixed Deposits',
        node: 'FD_INVESTMENT',
        nodeQuestions: [
          {
            questionId: 'FD_INVESTMENT',
            section: ASSETS_SECTION,
            question: 'How much approximate value have you invested in FDs?',
            questionOptionType: TEXT_INPUT,
            questionOption: [],
            selectedOption: [],
          },
        ],
      },
      REALESTATE_INVESTMENT: {
        level: 3,
        displayTemplate: 'I own *value_0* propertie(s) - *value_1*. Their combined value must be approximately *value_2*',
        node: 'REALESTATE_INVESTMENT',
        nodeQuestions: [
          {
            questionId: 'REALESTATE_COUNT',
            section: ASSETS_SECTION,
            question: 'How many propertie(s) do you own (including land, commercial, residential)?',
            questionOptionType: DROPDOWN_INPUT,
            questionOption: ['1', '2', '3', '4', '5', '6', '7', '8'],
            selectedOption: [],
          },
          {
            questionId: 'REALESTATE_TYPES',
            section: ASSETS_SECTION,
            question: 'Select all types of Real Estate that you own',
            questionOptionType: BUTTON_MULTI_SELECT_INPUT_TYPE,
            questionOption: ['Land', 'Flat', 'Bungalow / Row House', 'Commercial'],
            selectedOption: [],
          },
          {
            questionId: 'REALESTATE_VALUE',
            section: ASSETS_SECTION,
            question: 'What is the approximate current total value of all your properties put together?',
            questionOptionType: TEXT_INPUT,
            questionOption: [],
            selectedOption: [],
          },
        ],
      },
      INVESTMENTS_THANKYOU: {
        level: 4,
        displayTemplate: null,
        node: 'INVESTMENTS_THANKYOU',
        nodeQuestions: [
          {
            questionId: 'INVESTMENTS_THANKYOU',
            section: ASSETS_SECTION,
            question: 'Thank you for sharing your other investments with us. This helps us provide holistic investment advice towards a balanced approach to investing.',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['Thank You'],
            selectedOption: [],
          },
        ],
      },
    },
  },
  SERVICES: {
    levels: 3,
    nodes: {
      SERVICE_SELECTION: {
        level: 1,
        displayTemplate: null,
        node: 'SERVICE_SELECTION',
        nodeQuestions: [
          {
            questionId: 'SERVICE_SELECTION',
            section: SERVICES_SECTION,
            question: 'What can I help you with today ?',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['Capital Gains Statement', 'Portfolio Statement', 'Schedule Review Meeting'],
            selectedOption: [],
          },
        ],
      },
      CAPITAL_GAINS_STATEMENT: {
        level: 2,
        displayTemplate: null,
        node: 'CAPITAL_GAINS_STATEMENT',
        nodeQuestions: [
          {
            questionId: 'CAPITAL_GAINS_YEAR',
            section: SERVICES_SECTION,
            question: 'Select Financial Year',
            questionOptionType: DATE_INPUT,
            questionOption: [],
            selectedOption: [],
          },
        ],
      },
      CAPITAL_GAINS_THANKS: {
        level: 3,
        displayTemplate: null,
        node: 'CAPITAL_GAINS_THANKS',
        nodeQuestions: [
          {
            questionId: 'CAPITAL_GAINS_THANKS',
            section: SERVICES_SECTION,
            question: 'Your Support Request Number is WG-250341. Your Capital Gains Statement will be emailed shortly.',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['Thank You'],
            selectedOption: [],
          },
        ],
      },
      PORTFOLIO_THANKS: {
        level: 3,
        displayTemplate: null,
        node: 'PORTFOLIO_THANKS',
        nodeQuestions: [
          {
            questionId: 'PORTFOLIO_THANKS',
            section: SERVICES_SECTION,
            question: 'Your Support Request Number is WG-180345. Your Portfolio Statement will be emailed shortly.',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['Thank You'],
            selectedOption: [],
          },
        ],
      },
      REVIEW_MEETING_THANKS: {
        level: 3,
        displayTemplate: null,
        node: 'REVIEW_MEETING_THANKS',
        nodeQuestions: [
          {
            questionId: 'REVIEW_MEETING_THANKS',
            section: SERVICES_SECTION,
            question: 'Your Support Request Number is WG-311452. Your Review meeting has been scheduled.',
            questionOptionType: BUTTON_INPUT_TYPE,
            questionOption: ['Thank You'],
            selectedOption: [],
          },
        ],
      },
    },
  },
};


export function nextStateDecisonEngine(state: answersObject, currentNode: string): string {
  let nextState = '';
  switch (currentNode) {
    case 'LINKEDIN_URL':
      nextState = 'RELATIONSHIP_STATUS';
      break;
    case 'RELATIONSHIP_STATUS':
      if (state['RELATIONSHIP_STATUS'].includes('Single')) {
        nextState = 'MARRIAGE_PLAN';
      } else {
        nextState = 'CHILDREN_STATUS';
      }
      break;
    case 'MARRIAGE_PLAN':
      if (state['MARRIAGE_PLAN'].includes('Yes')) {
        nextState = 'MARRIAGE_DATE_PLANNING';
      } else {
        nextState = 'OTHER_GOALS';
      }
      break;

    case 'MARRIAGE_DATE_PLANNING':
      nextState = 'MARRIAGE_AMOUNT';
      break;
    case 'MARRIAGE_AMOUNT':
      nextState = 'OTHER_GOALS';
      break;
    case 'CHILDREN_STATUS':
      if (state['CHILDREN_STATUS'].includes('Yes')) {
        nextState = 'CHILDREN_NUMBER';
      } else {
        nextState = 'OTHER_GOALS';
      }
      break;

    case 'CHILDREN_NUMBER':
      nextState = 'CHILDREN_EDUCATION_AMOUNT';

      break;

    case 'CHILDREN_EDUCATION_AMOUNT':
      nextState = 'OTHER_GOALS';
      break;

    case 'OTHER_GOALS':
      if (state['OTHER_GOALS'].includes('Car')) {
        nextState = 'CAR_DETAILS';
      } else if (state['OTHER_GOALS'].includes('House')) {
        nextState = 'HOUSE_DETAILS';
      } else if (state['OTHER_GOALS'].includes('Retirement')) {
        nextState = 'RETIREMENT_DETAILS';
      } else if (state['OTHER_GOALS'].includes('Financial Freedom')) {
        nextState = 'FIN_FREEDOM_DETAILS';
      } else if (state['OTHER_GOALS'].includes('Vacation')) {
        nextState = 'VACATION_DETAILS';
      } else if (state['OTHER_GOALS'].includes('Emergency Corpus')) {
        nextState = 'EMERGENCY_CORPUS_DETAILS';
      } else if (state['OTHER_GOALS'].includes('Done')) {
        nextState = 'GOALS_THANKYOU';
      }
      break;

    case 'CAR_DETAILS':
      nextState = 'OTHER_GOALS';
      break;

    case 'HOUSE_DETAILS':
      nextState = 'OTHER_GOALS';
      break;

    case 'RETIREMENT_DETAILS':
      nextState = 'OTHER_GOALS';
      break;

    case 'FIN_FREEDOM_DETAILS':
      nextState = 'OTHER_GOALS';
      break;
    
    case 'VACATION_DETAILS':
      nextState = 'OTHER_GOALS';
      break;

    case 'EMERGENCY_CORPUS_DETAILS':
      nextState = 'OTHER_GOALS';
      break;
    
    case 'GOALS_THANKYOU':
      if (state['GOALS_THANKYOU'].includes('Thank You')) {
        nextState = 'END';
      }
      break;

    case 'EMI_STATUS':
      if (state['EMI_STATUS'].includes('Yes')) {
        nextState = 'LOAN_TYPES';
      } else {
        nextState = 'END';
      }
      break;

    case 'LOAN_TYPES':
      if (state['LOAN_TYPES'].includes('Home Loan')) {
        nextState = 'HOME_LOAN_DETAILS';
      } else if (state['LOAN_TYPES'].includes('Vehicle Loan')) {
        nextState = 'VEHICLE_LOAN_DETAILS';
      } else if (state['LOAN_TYPES'].includes('Education Loan')) {
        nextState = 'EDUCATION_LOAN_DETAILS';
      } else if (state['LOAN_TYPES'].includes('Personal Loan')) {
        nextState = 'PERSONAL_LOAN_DETAILS';
      } else if (state['LOAN_TYPES'].includes('Done')) {
        nextState = 'LOAN_TYPES_THANKYOU';
      }
      break;

    case 'HOME_LOAN_DETAILS':
      nextState = 'LOAN_TYPES';
      break;

    case 'VEHICLE_LOAN_DETAILS':
      nextState = 'LOAN_TYPES';
      break;

    case 'EDUCATION_LOAN_DETAILS':
      nextState = 'LOAN_TYPES';
      break;

    case 'PERSONAL_LOAN_DETAILS':
      nextState = 'LOAN_TYPES';
      break;

    case 'LOAN_TYPES_THANKYOU':
      if (state['LOAN_TYPES_THANKYOU'].includes('Thank You')) {
        nextState = 'END';
      }
      break;

    case 'INVESTMENT_STATUS':
      if (state['INVESTMENT_STATUS'].includes('Yes')) {
        nextState = 'INVESTMENT_TYPES';
      } else {
        nextState = 'END';
      }
      break;

    case 'INVESTMENT_TYPES':
      if (state['INVESTMENT_TYPES'].includes('Stocks')) {
        nextState = 'STOCKS_INVESTMENT';
      } else if (state['INVESTMENT_TYPES'].includes('Mutual Funds')) {
        nextState = 'MUTUALFUNDS_INVESTMENT';
      } else if (state['INVESTMENT_TYPES'].includes('PPF')) {
        nextState = 'PPF_INVESTMENT';
      } else if (state['INVESTMENT_TYPES'].includes('PF')) {
        nextState = 'PF_INVESTMENT';
      } else if (state['INVESTMENT_TYPES'].includes('FDs')) {
        nextState = 'FD_INVESTMENT';
      } else if (state['INVESTMENT_TYPES'].includes('Real Estate')) {
        nextState = 'REALESTATE_INVESTMENT';
      } else if (state['INVESTMENT_TYPES'].includes('Done')) {
        nextState = 'INVESTMENTS_THANKYOU';
      } 
      break;

    case 'STOCKS_INVESTMENT':
      nextState = 'INVESTMENT_TYPES';
      break;
    
    case 'MUTUALFUNDS_INVESTMENT':
      nextState = 'INVESTMENT_TYPES';
      break;
    
    case 'PPF_INVESTMENT':
      nextState = 'INVESTMENT_TYPES';
      break;
    
    case 'PF_INVESTMENT':
      nextState = 'INVESTMENT_TYPES';
      break;
    
    case 'FD_INVESTMENT':
      nextState = 'INVESTMENT_TYPES';
      break;
    
    case 'REALESTATE_INVESTMENT':
      nextState = 'INVESTMENT_TYPES';
      break;
    
    case 'INVESTMENTS_THANKYOU':
      if (state['INVESTMENTS_THANKYOU'].includes('Thank You')) {
        nextState = 'END';
      }
      break;

    case 'SERVICE_SELECTION':
      if (state['SERVICE_SELECTION'].includes('Capital Gains Statement')) {
        nextState = 'CAPITAL_GAINS_STATEMENT';
      } else if (state['SERVICE_SELECTION'].includes('Portfolio Statement')) {
        nextState = 'PORTFOLIO_THANKS';
      } else if (state['SERVICE_SELECTION'].includes('Schedule Review Meeting')) {
        nextState = 'REVIEW_MEETING_THANKS';
      }
      break;

    case 'CAPITAL_GAINS_STATEMENT':
      nextState = 'CAPITAL_GAINS_THANKS';
      break;

    case 'CAPITAL_GAINS_THANKS':
      nextState = 'END';
      break;

    case 'PORTFOLIO_THANKS':
      nextState = 'END';
      break;

    case 'REVIEW_MEETING_THANKS':
      nextState = 'END';
      break;

    default:
    // Code to execute if no cases match
  }

  return nextState;
}
