import { createTheme, responsiveFontSizes } from '@mui/material/styles';


// Step 1: Define the theme with typography and breakpoints
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
export default theme;
