import { TextField } from '@mui/material';

import { onUserInput } from '../common/types';

type ChatOptionInputProps = {
  value: string;
  type: string;
  onBlur: onUserInput;
  index: number[];
};
const ChatOptionInput = ({ value, type, onBlur, index }: ChatOptionInputProps) => {
  return (
    <>
      <TextField
        variant="outlined"
        type={type}
        size="small"
        sx={{ fontSize: '0.6rem' }}
        onChange={(event) => {
          onBlur(index, event.target.value, true);
        }}
        value={value}
      />
    </>
  );
};

export default ChatOptionInput;
