import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Button, useMediaQuery } from '@mui/material';
import { PURPLE } from '../design-constants';
import PhoneLogin from '../components/phone-login';
import Otp from '../components/otp-input';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// Sleep function remove later
function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const Login = () => {
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState('');
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [invalidPhonenumber, setinvalidPhonenumber] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (timeLeft <= 0) return; // Stop the timer at 0

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1); // Decrease time by 1 second
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on unmount
  }, [timeLeft]);

  const [userInfo, setUserInfo] = useState({
    phoneNumber: '',
    name: '',
    userId: '',
  });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  function handleOtpResend() {
    // Call Resend API
    setTimeLeft(30);
  }
  function handleOtpInput(value: string) {
    setOtp(value);
  }

  function handlePhoneNumberInput(value: string) {
    setUserInfo({ ...userInfo, phoneNumber: value });
  }

  function handleSubmitOtp() {
    // Call Submit OTP API, If error set setInvalidOtp(true)
    // Set AUTH_TOKEN in cookies
    // Else
    setInvalidOtp(false);
    navigate('/investor-overview');
  }

  async function handleSendOtp() {
    if (!userInfo.name && !userInfo.userId) {
      if (userInfo.phoneNumber.length !== 10) {
        setinvalidPhonenumber(true);

        return;
      } else {
        setinvalidPhonenumber(false);
        setTimeLeft(30);
      }
    }

    await sleep(200);
    setShowOtp(true);
    // try {
    //   // Simulate the API call
    //   const response = await fetch('https://example.com/api/submit', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ phoneNumber, name }),
    //   });

    //   // Simulate a successful response
    //   if (!response.ok) {
    //     throw new Error(`HTTP error! status: ${response.status}`);
    //   }

    //   const data = await response.json();

    //   // Log the success message
    //   console.log(data.message);
    // } catch (error) {
    //   console.error('Error:', error.message);
    // }
  }

  useEffect(() => {
    const data = searchParams?.get('data');
    if (data) {
      // Logic to decode JWT token
      const userdata = JSON.parse(atob(data));
      if (Object.keys(userdata).length > 0) {
        setUserInfo({ ...userInfo, ...userdata });
      }
    }
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        padding: '2rem',
      }}
    >
      {showOtp ? (
        <Otp
          otp={otp}
          handleOtpInput={handleOtpInput}
          timer={timeLeft}
          handleResend={handleOtpResend}
          handleSubmitOtp={handleSubmitOtp}
          invalidOtp={invalidOtp}
        />
      ) : (
        <PhoneLogin
          showTextBox={!!userInfo.userId}
          handlePhoneNumberInput={handlePhoneNumberInput}
          invalidPhoneNumber={invalidPhonenumber}
          handleSendOtp={handleSendOtp}
          phoneNumber={userInfo.phoneNumber}
          userName={userInfo.name}
        />
      )}
    </Box>
  );
};

export default Login;
