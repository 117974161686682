import React from 'react';
import { Button } from '@mui/material';

import { DARK_BLUE, BLUE, WHITE } from '../design-constants';
import { onUserInput } from '../common/types';

type ChatOptionButtonProps = {
  children: string;
  selected: boolean;
  onClick: onUserInput;
  index: number[];
};
const ChatOptionButton = ({ children, selected, onClick, index }: ChatOptionButtonProps) => {
  return (
    <>
      <Button
        id={children}
        className="selected"
        sx={{
          background: selected ? BLUE : WHITE,
          color: selected ? 'white' : DARK_BLUE,
          border: selected ? `0.1rem solid ${DARK_BLUE}`: `0.1rem solid ${BLUE}`,
          textTransform: 'unset',
          fontWeight: 500,
          fontSize: '0.8rem',
        }}
        onClick={() => onClick(index, children, !selected)}
      >
        {children}
      </Button>
    </>
  );
};

export default ChatOptionButton;
