import { createContext } from 'react';
import { answersObject, onBoardingProgessState } from './types';

// Define types for the context value
interface GlobalStateContextType {
  completeOnboardingState: answersObject;
  onboardingProgressState: onBoardingProgessState;
  updateCompleteOnboardingState: (currentQuestionAnswer: answersObject) => void;
  updateProgressState: (partialProgressState: onBoardingProgessState) => void;
}

export const GlobalState = createContext<GlobalStateContextType | null>(null);
