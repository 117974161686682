import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Paper, useMediaQuery, useTheme } from '@mui/material';
import { BLUE, DARK_BLUE, PURPLE } from '../design-constants';
import { useNavigate } from 'react-router-dom';

const InvestorOverview = () => {
    const theme = useTheme();
  const min_width_600 = useMediaQuery(theme.breakpoints.up('sm'));
  const [investorData, setInvestorData] = useState('');
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // API call to get investor data

    setInvestorData('You have invested 20L which are now 25L. That’s almost 25% growth! Great going!');
    // Retrieve username  from authtoken or the above API
    setUserName('Anand');
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{
        maxWidth: min_width_600 ? '30%' : '80%',
        margin: 'auto',
        padding: "1.5rem",
        textAlign: 'center',
        backgroundColor: 'white',
        borderRadius: 2,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {' '}
        <Typography sx={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
          Welcome aboard, {userName}
        </Typography>
        <Typography sx={{ fontSize: '1rem', textAlign: 'left' }}>{investorData}</Typography>
        <Typography sx={{ fontSize: '1rem', textAlign: 'left' }}>
          For that, we’d like to learn a little more about you so we can help you get the most out of Wealth Genie.
        </Typography>
        <Box sx={{ alignSelf: 'flex-end' }}>
          <Button
            onClick={() => navigate('/onboarding')}
            variant="contained"
            sx={{ backgroundColor: BLUE, textTransform: 'unset', border: `0.1rem solid ${BLUE}`, fontWeight: 500, fontSize: '0.8rem'}}
            disableElevation={true}
          >
            Let’s Start
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default InvestorOverview;
