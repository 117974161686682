import { Box, selectClasses } from '@mui/material';
import { useState, useContext, useEffect, useRef, createRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { BUTTON_INPUT_TYPE, GOALS_ONBOARDING_LS, GOALS_SECTION } from '../common/constants';
import { GlobalState } from '../common/hooks';
import { OnboardingStateSection } from '../common/types';
import ChatContainer from '../components/chat-container';
import HeaderBar from '../components/header-bar';
import { nextStateDecisonEngine, ONBOARDING_STATE_GRAPH } from '../onboarding-state-graph';
import { checkIfAllNodeQuestionsAnswered, erasePreviousState, handleUserInput } from '../common/utils';
import UpdatePreviousStateModal from '../components/update-previous-state-modal';

type TempUserInputStorage = {
  index: number[];
  value: string;
  selected: boolean;
};

type OnboardingType = {
  startingNode: OnboardingStateSection;
  config: {
    id: string;
    title: string;
  };
};

const Onboarding = ({ startingNode, config }: OnboardingType) => {
  const { completeOnboardingState, onboardingProgressState, updateCompleteOnboardingState, updateProgressState } =
    useContext(GlobalState) || {};
  const [onboardingState, setOnboardingState] = useState<OnboardingStateSection[]>([startingNode]);
  const [tempUserInputStorage, setTempUserInputStorage] = useState<TempUserInputStorage | null>(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const messagesEndRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight - 600;
    }
  }, [onboardingState, messagesEndRef]);

  useEffect(() => {
    const state = localStorage.getItem(`${config.id.toLowerCase()}OnboardingState`);
    if (state) {
      setOnboardingState(JSON.parse(state));
    }
  }, []);

  const handleModalConfirm = () => {
    if (tempUserInputStorage) {
      const updatedCurrentState = eraseSubsequentResponses(tempUserInputStorage?.index[0]);
      onboardingStatesUpdation(
        updatedCurrentState,
        tempUserInputStorage?.index,
        tempUserInputStorage?.value,
        tempUserInputStorage?.selected
      );
      setTempUserInputStorage(null);
      setShowModal(false);
    }
  };

  const eraseSubsequentResponses = (index: number) => {
    const currentState = structuredClone(onboardingState);
    const updatedCurrentState = currentState.slice(0, index + 1);
    const deletedState = currentState.slice(index);
    const completeOnboardingStateClone = structuredClone(completeOnboardingState);
    const updatedCompleteOnboardingState = erasePreviousState(deletedState, completeOnboardingStateClone);
    if (updateCompleteOnboardingState) {
      updateCompleteOnboardingState(updatedCompleteOnboardingState);
    }

    if (updateProgressState) {
      updateProgressState({
        [config.id]:
          (updatedCurrentState[updatedCurrentState.length - 1].level / ONBOARDING_STATE_GRAPH[config.id]['levels']) *
          100,
      });
    }
    return updatedCurrentState;
  };

  function onboardingStatesUpdation(
    currentState: OnboardingStateSection[],
    index: number[],
    value: string,
    selected: boolean
  ) {
    const changedNode = currentState[index[0]];

    const latestNode = currentState[currentState.length - 1];

    const changedNodeQuestion = changedNode.nodeQuestions[index[1]];

    changedNodeQuestion['selectedOption'] = handleUserInput(
      changedNodeQuestion['questionOptionType'],
      changedNodeQuestion['questionOption'],
      changedNodeQuestion['selectedOption'],

      value,
      selected
    );
    const allNodeQuestionsAnswered = checkIfAllNodeQuestionsAnswered(latestNode.nodeQuestions);
    if (index[0] !== currentState.length - 1) {
      if (index[0] === currentState.length - 2) {
        if (allNodeQuestionsAnswered) {
          setTempUserInputStorage({ index, value, selected });
          setShowModal(true);
          return;
        } else {
          const updatedCurrentState = eraseSubsequentResponses(index[0]);
          onboardingStatesUpdation(updatedCurrentState, index, value, selected);

          return;
        }
      } else {
        setTempUserInputStorage({ index, value, selected });
        setShowModal(true);
        return;
      }
    }
    const nextState = nextStateDecisonEngine(
      {
        ...completeOnboardingState,
        [changedNodeQuestion['questionId']]: changedNodeQuestion['selectedOption'],
      },
      changedNode.node
    );

    if (nextState !== 'END' && allNodeQuestionsAnswered) {
      currentState.push(ONBOARDING_STATE_GRAPH[config.id]['nodes'][nextState]);
    }

    localStorage.setItem(`${config.id.toLowerCase()}OnboardingState`, JSON.stringify(currentState));

    setOnboardingState((prev) => currentState);

    if (allNodeQuestionsAnswered) {
      if (updateProgressState) {
        if (nextState === 'END') {
          updateProgressState({
            [config.id]: 100,
          });
        } else {
          updateProgressState({
            [config.id]:
              (currentState[currentState.length - 2].level / ONBOARDING_STATE_GRAPH[config.id]['levels']) * 100,
          });
        }
      }
    }

    if (updateCompleteOnboardingState) {
      updateCompleteOnboardingState({
        [changedNodeQuestion['questionId']]: changedNodeQuestion['selectedOption'],
      });
    }
  }

  const onUserInput = (index: number[], value: string, selected: boolean) => {
    const currentState = structuredClone(onboardingState);
    onboardingStatesUpdation(currentState, index, value, selected);
  };
  return (
    <>
      <UpdatePreviousStateModal
        open={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
        handleConfirm={handleModalConfirm}
      />
      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      > */}{' '}
      <HeaderBar
        pageTitle={config.title}
        progress={onboardingProgressState?.[config.id] ?? 0}
        onBackClick={async () => navigate(-1)}
      ></HeaderBar>
      <Box
        ref={messagesEndRef}
        sx={{
          overflowY: 'scroll', // Allows scrolling when content overflows
          marginBottom: '1rem',
        }}
      >
        <ChatContainer currentOnBoardingState={onboardingState} onUserInput={onUserInput} />
      </Box>
      {/* </Box> */}
    </>
  );
};

export default Onboarding;
