import React, { useContext } from 'react';
import { Box, Typography, Paper, Stack, CircularProgress, Fab } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import { PROFILE_BUIDER_PAGE } from '../common/constants';
import { DARK_BLUE } from '../design-constants';
import { GlobalState } from '../common/hooks';

function ProfileBuilderSection(
  title: string,
  subtitle: string,
  progress: number,
  icon: () => React.ReactNode,
  onClickHandler: () => void
) {
  return (
    <Paper key={title} elevation={3} sx={{ cursor: 'pointer', margin: 0 }} onClick={onClickHandler}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
          padding: '1rem',

          gap: '0.8rem',
        }}
      >
        {icon()}
        <Box sx={{ width: '80%' }}>
          <Typography sx={{ fontSize: '1.1rem', fontWeight: 600, textAlign: 'start' }}>{title}</Typography>
          <Typography sx={{ fontSize: '0.9rem', textAlign: 'start' }}>{subtitle}</Typography>
        </Box>
        {progress < 100 ? (
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" value={progress} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography sx={{ fontSize: '0.8rem', fontWeight: 600 }} component="div">{`${Math.round(
                progress
              )}%`}</Typography>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              color: 'white',
              backgroundColor: 'green',
              width: '2.5rem',
              height: '2.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '3rem',
            }}
          >
            <CheckIcon />
          </Box>
        )}
      </Box>
    </Paper>
  );
}

function ProfileBuilder() {
  const navigate = useNavigate();

  const { completeOnboardingState, onboardingProgressState, updateCompleteOnboardingState, updateProgressState } =
    useContext(GlobalState) || {};

  return (
    <Box
      maxWidth="sm"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        justifySelf: 'center',
        height: '95%',
      }}
    >
      {' '}
      <Box
        sx={{
          borderRadius: '1rem',
          bgcolor: 'white',
          padding: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'start',
          gap: '1rem',
          maxWidth: '80%',
        }}
      >
        {' '}
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'start' }}>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: '1.2rem', fontWeight: 500, lineHeight: 1.2, textAlign: 'start' }}
          >
            {PROFILE_BUIDER_PAGE.titleFirstLine}, {PROFILE_BUIDER_PAGE.titleSecondLine}
          </Typography>
          <Typography sx={{ fontSize: '1.3rem', fontWeight: 500, lineHeight: 1.2, textAlign: 'start' }}>
            
          </Typography>
        </Box>
        <Stack spacing={1}>
          {PROFILE_BUIDER_PAGE.section.map((section) =>
            ProfileBuilderSection(
              section.title,
              section.subTitle,
              onboardingProgressState ? onboardingProgressState[section.sectionId] : 0,
              section.icon,
              () => {
                navigate(section.navigate);
              }
            )
          )}
        </Stack>
      </Box>
    </Box>
  );
}

export default ProfileBuilder;
