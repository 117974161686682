import { Box } from '@mui/material';
import { OnboardingStateSection, onUserInput } from '../common/types';

import HumanMessage from './human-message';
import SystemMessage from './system-message';

const RenderMessages = (nodeData: OnboardingStateSection, NodeIndex: number[], onUserInput: onUserInput) => {
  let humanResponses = [];
  let finalResponse = '';

  let showUserMesg = true;

  for (let i = 0; i < nodeData.nodeQuestions.length; i++) {
    const nodeElement = nodeData.nodeQuestions[i];
    if (nodeElement.selectedOption.length === 0) {
      showUserMesg = false;
      break;
    }
    let humanMsg = '';
    if (nodeElement.selectedOption.length > 0) {
      for (let j = 0; j < nodeElement.selectedOption.length; j++) {
        if (j === 0) {
          humanMsg = `${nodeElement.selectedOption[j]}`;
          continue;
        }
        humanMsg = `${humanMsg}, ${nodeElement.selectedOption[j]}`;
      }
    }
    humanResponses.push(humanMsg);
  }

  if (nodeData.displayTemplate == null) {
    let temp = '';
    for (let i = 0; i < humanResponses.length; i++) {
      temp = `${temp} ${humanResponses[i]}`;
    }
    finalResponse = temp;
  } else {
    finalResponse = nodeData.displayTemplate;
    for (let i = 0; i < humanResponses.length; i++) {
      finalResponse = finalResponse.replace(`*value_${i}*`, humanResponses[i]);
    }
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '1rem',
        width: '100%',
        marginBottom:"1rem"
      }}
      key={nodeData.node}
    >
      <SystemMessage nodeData={nodeData} nodeIndex={NodeIndex} onUserInput={onUserInput} />
      {showUserMesg && <HumanMessage>{finalResponse}</HumanMessage>}
    </Box>
  );
};

const ChatContainer = ({
  currentOnBoardingState,
  onUserInput,
}: {
  currentOnBoardingState: OnboardingStateSection[];
  onUserInput: onUserInput;
}) => {
  return (
    <>
      <Box
        sx={{
          padding: '1rem 1rem',

          height: '100%',
        }}
      >
        {currentOnBoardingState.map((nodeData, index) => RenderMessages(nodeData, [index], onUserInput))}
      </Box>
    </>
  );
};

export default ChatContainer;
