import { Avatar, Box } from '@mui/material';

import { DARK_BLUE, LIGHT_BLUE } from '../design-constants';

type HumanMessageProps = {
  children: string;
};
const HumanMessage = ({ children }: HumanMessageProps) => {
  return (
    <>
      <Box sx={{ display: 'flex', gap: '0.5rem',alignSelf:"flex-end", alignItems: 'center' ,gridColumn:"2/4",justifySelf:"flex-end"}}>
        
        <Box
          id={children}
          className="selected"
          sx={{
            background: LIGHT_BLUE,
            color: DARK_BLUE,
            border: `0.1rem solid ${DARK_BLUE}`,
            padding: '0.6rem',
            fontWeight: 550,
            fontSize: '0.8rem',
            borderRadius: '0.5rem',
            width: 'fit-content',
          }}
        >
          {children}
        </Box>
        <Avatar sx={{ width:"2.5rem", height: "2.5rem"}}>SB</Avatar>
      </Box>
    </>
  );
};

export default HumanMessage;
