// import React, { useState } from 'react';
import { Avatar, Box } from '@mui/material';
import ChatOptionButton from './button';

import { Typography } from '@mui/material';
import {
  BUTTON_INPUT_TYPE,
  BUTTON_MULTI_SELECT_INPUT_TYPE,
  DATE_INPUT,
  DROPDOWN_INPUT,
  NUMERIC_INPUT,
  TEXT_INPUT,
} from '../common/constants';
import { OnboardingStateNode, OnboardingStateSection, onUserInput } from '../common/types';
import WealthGenie from '../assets/wealth-genie';
import Dropdown from './dropdown';
import ChatOptionInput from './input';
import ChatOptionDatePicker from './chat-datepicker';

const messageOptionType = (elements: OnboardingStateNode, index: number[], onUserInput: onUserInput) => {
  if (
    elements.questionOptionType === BUTTON_INPUT_TYPE ||
    elements.questionOptionType === BUTTON_MULTI_SELECT_INPUT_TYPE
  ) {
    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.8rem',flexWrap:"wrap" }}>
          {elements.questionOption.map((element) => {
            const selected = elements.selectedOption.includes(element);
            return (
              <ChatOptionButton key={element} selected={selected} onClick={onUserInput} index={index}>
                {element}
              </ChatOptionButton>
            );
          })}
        </Box>
      </>
    );
  } else if (elements.questionOptionType === DROPDOWN_INPUT) {
    const [selected] = elements.selectedOption;
    return (
      <Dropdown selectedValue={selected} onClick={onUserInput} index={index} dropDownItems={elements.questionOption} />
    );
  } else if (elements.questionOptionType === NUMERIC_INPUT) {
    const [value] = elements.selectedOption;
    return <ChatOptionInput value={value} type="number" onBlur={onUserInput} index={index} />;
  } else if (elements.questionOptionType === TEXT_INPUT) {
    const [value] = elements.selectedOption;
    return <ChatOptionInput value={value} type="text" onBlur={onUserInput} index={index} />;
  } else if (elements.questionOptionType === DATE_INPUT) {
    const [value] = elements.selectedOption;
    return <ChatOptionDatePicker value={value} type="text" onChange={onUserInput} index={index} />;
  }
};

const MessageContent = (nodeElements: OnboardingStateNode, index: number[], onUserInput: onUserInput) => {
  return (
    <Box key={nodeElements.questionId}>
      <Typography sx={{ textAlign: 'left', fontSize: '0.9rem' }}>{nodeElements.question}</Typography>
      <Box sx={{ marginTop: 1 }}>{messageOptionType(nodeElements, index, onUserInput)}</Box>
    </Box>
  );
};
const SystemMessage = ({
  nodeData,
  nodeIndex,
  onUserInput,
}: {
  nodeData: OnboardingStateSection;
  nodeIndex: number[];
  onUserInput: onUserInput;
}) => {
  return (
    <>
      <Box sx={{ display: 'flex', gap: '0.5rem', alignSelf: 'flex-start', alignItems: 'center', gridColumn: '1/3' }}>
        <Avatar sx={{ width: '3rem', height: '3rem', bgcolor: 'white' }}>
          <WealthGenie sx={{ fontSize: '2.5rem' }} />
        </Avatar>
        <Box
          sx={{
            display: 'flex',
            gap: '0.8rem',
            flexDirection: 'column',
            padding: '1rem',
            borderRadius: '0.5rem',
            backgroundColor: 'white',
            width: 'fit-content',
          }}
        >
          {nodeData.nodeQuestions.map((nodeQuestion, index) =>
            MessageContent(nodeQuestion, [...nodeIndex, index], onUserInput)
          )}
        </Box>
      </Box>
    </>
  );
};

export default SystemMessage;
