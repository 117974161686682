import { Avatar, Box, Button, Typography } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import WealthGenie from '../assets/wealth-genie';
import {LIGHT_GREY, BLUE } from '../design-constants';

const NavBar = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '0.5rem',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: "white",
          padding: '0rem 1.6rem',
     
         
        }}
      >
    
          <WealthGenie sx={{ fontSize: '4rem' }} />
          <Button variant="text" sx={{color:BLUE, fontSize:"0.8rem",fontWeight:550,}} > <PowerSettingsNewIcon sx={{ paddingRight: 1}}/> Logout</Button>
        {/* <Typography sx={{ fontSize: '1.2rem', fontWeight: 600, lineHeight: 1.2 }}>Wealth Genie</Typography> */}
      </Box>
    </>
  );
};

export default NavBar;
