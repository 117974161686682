import AssetsIcon from '../assets/assets';
import LiabilitiesIcon from '../assets/liabilities';
import PersonalDetailsIcon from '../assets/personal-details';
import ServicesIcon from '../assets/services';

// INPUT TYPE
export const BUTTON_INPUT_TYPE = 'ButtonGroup';
export const BUTTON_MULTI_SELECT_INPUT_TYPE = 'ButtonGroupMultiSelect';
export const DROPDOWN_INPUT = 'DropdownInput';
export const NUMERIC_INPUT = 'NumericInput';
export const TEXT_INPUT = 'TextInput';
export const DATE_INPUT = 'DateInput';

export const GOALS_SECTION = 'GOALS';
export const LIABILITIES_SECTION = 'LIABILITIES';
export const ASSETS_SECTION = 'ASSETS';
export const SERVICES_SECTION = 'SERVICES';

export const GLOBAL_STATE = 'globalState';

// Local Storage Keys
export const GOALS_ONBOARDING_LS = 'goalsOnboardingState';
export const COMPLETE_GLOBAL_ONBOARDING_STATE_LS = 'completeGlobalOnboardingState';
export const GLOBAL_PROGRESS_STATE_LS = 'globalProgressState';

export const ONBOARDING_PROGRESS_INITIAL_STATE = {
  GOALS: 0,
  LIABILITIES: 0,
  ASSETS: 0,
  SERVICES: 0,
};

export const CONTEXTS = {
  GOALS_PROGRESS: 'goalsProgress',
  COMPLETE_ONBOARDING_STATE: 'completeOnboardingState',
};

export const PROFILE_BUIDER_PAGE = {
  titleFirstLine: "Let's build your profile",
  titleSecondLine: 'what would you like to focus on first?',

  section: [
    {
      sectionId: 'GOALS',
      title: 'Personal Details',
      subTitle: 'Your background and preferences to help us personalize your experience',
      navigate: '/onboarding/goals',
      icon: () => <PersonalDetailsIcon sx={{ fontSize: '3rem' }} />,
    },
    {
      sectionId: 'LIABILITIES',
      title: 'Loans & Expenses',
      subTitle: 'All about the amounts you owe or spend, like mortgages, credit card debt or monthly bills.',
      navigate: '/onboarding/liabilities',
      icon: () => <LiabilitiesIcon sx={{ fontSize: '3rem' }} />,
    },
    {
      sectionId: 'ASSETS',
      title: 'Assets',
      subTitle: 'Everything about savings, and investments - basically anything you own that adds to your wealth',
      navigate: '/onboarding/assets',
      icon: () => <AssetsIcon sx={{ fontSize: '3rem' }} />,
    },
    {
      sectionId: 'SERVICES',
      title: 'Services',
      subTitle: 'Raise Service Support Requests to help us serve you effectively',
      navigate: '/onboarding/services',
      icon: () => <ServicesIcon sx={{ fontSize: '3rem' }} />,
    },
  ],
};

export const ONBOARDING_PAGE = {
  GOALS: {
    id: 'GOALS',
    name: 'goals',
    title: 'Personal Details',
  },
  LIABILITIES: {
    id: 'LIABILITIES',
    name: 'liabilties',
    title: 'Liabiltities',
  },
  ASSETS: {
    id: 'ASSETS',
    name: 'Assets',
    title: 'Assets',
  },
  SERVICES: {
    id: 'SERVICES',
    name: 'Services',
    title: 'Services',
  },
};
