import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import ProfileBuilder from './screens/profile-builder';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Onboarding from './screens/onboarding';
import NavBar from './components/nav-bar';
import { CONTEXTS, ONBOARDING_PAGE } from './common/constants';
import MainContainer from './screens/main-app-container';
import { ONBOARDING_STATE_GRAPH } from './onboarding-state-graph';
import Login from './screens/login';
import InvestorOverview from './screens/investor-overview';
import theme from './theme';

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/onboarding',
    element: <ProfileBuilder />,
  },
  {
    path: '/investor-overview',
    element: <InvestorOverview />,
  },
  {
    path: '/onboarding/goals',
    element: (
      <Onboarding startingNode={ONBOARDING_STATE_GRAPH.GOALS.nodes.LINKEDIN_URL} config={ONBOARDING_PAGE.GOALS} />
    ),
  },
  {
    path: '/onboarding/liabilities',
    element: (
      <Onboarding
        startingNode={ONBOARDING_STATE_GRAPH.LIABILITIES.nodes.EMI_STATUS}
        config={ONBOARDING_PAGE.LIABILITIES}
      />
    ),
  },
  {
    path: '/onboarding/assets',
    element: (
      <Onboarding
        startingNode={ONBOARDING_STATE_GRAPH.ASSETS.nodes.INVESTMENT_STATUS}
        config={ONBOARDING_PAGE.ASSETS}
      />
    ),
  },
  {
    path: '/onboarding/services',
    element: (
      <Onboarding
        startingNode={ONBOARDING_STATE_GRAPH.SERVICES.nodes.SERVICE_SELECTION}
        config={ONBOARDING_PAGE.SERVICES}
      />
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MainContainer>
        <NavBar />
        <RouterProvider router={router} />
      </MainContainer>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
