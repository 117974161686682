import React, { useState } from 'react';
import { Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { onUserInput } from '../common/types';

type DropdownButtonProps = {
  dropDownItems: string[];
  selectedValue: string;
  onClick: onUserInput;
  index: number[];
};
const Dropdown = ({ dropDownItems, selectedValue, onClick, index }: DropdownButtonProps) => {
  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <Select
          value={selectedValue}
          defaultValue={""}
          sx={{fontSize:"0.8rem"}}
          onChange={(event: SelectChangeEvent) => onClick(index, event.target.value as string, true)}
        >
          {dropDownItems.map((dropDownItem) => (
            <MenuItem key={dropDownItem} sx={{fontSize:"0.8rem"}}value={dropDownItem}>{dropDownItem}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default Dropdown;
