import { Box } from '@mui/material'; // Assuming you are using MUI's Box component
import React, { createContext, useEffect, useState } from 'react';
import {
  COMPLETE_GLOBAL_ONBOARDING_STATE_LS,
  GLOBAL_PROGRESS_STATE_LS,
  ONBOARDING_PROGRESS_INITIAL_STATE,
} from '../common/constants';
import { GlobalState } from '../common/hooks';
import { answersObject, onBoardingProgessState } from '../common/types';
import background from '../assets/background.png'
import { BACKGROUND_GREY, LIGHT_GREY } from '../design-constants';

// Type for the MainContainer props
interface MainContainerProps {
  children: React.ReactNode; // Children can be any valid React node
}

const MainContainer: React.FC<MainContainerProps> = ({ children }) => {
  const [completeOnboardingState, setCompleteOnboardingState] = useState<answersObject>({});
  const [onboardingProgressState, setOnboardingProgressState] = useState<onBoardingProgessState>(
    ONBOARDING_PROGRESS_INITIAL_STATE
  );

  const updateCompleteOnboardingState = (currentQuestionAnswer: answersObject) => {
    setCompleteOnboardingState((prev) => {
      localStorage.setItem(
        COMPLETE_GLOBAL_ONBOARDING_STATE_LS,
        JSON.stringify({
          ...prev,
          ...currentQuestionAnswer,
        })
      );

      return {
        ...prev,
        ...currentQuestionAnswer,
      };
    });
  };

  const updateProgressState = (partialProgressState: onBoardingProgessState) => {
    setOnboardingProgressState((prev) => {
      localStorage.setItem(
        GLOBAL_PROGRESS_STATE_LS,
        JSON.stringify({
          ...prev,
          ...partialProgressState,
        })
      );

      return {
        ...prev,
        ...partialProgressState,
      };
    });
  };

  useEffect(() => {
    const progressState = localStorage.getItem(GLOBAL_PROGRESS_STATE_LS);
    if (progressState) {
      setOnboardingProgressState(JSON.parse(progressState));
    }

    const globalOnboardingState = localStorage.getItem(COMPLETE_GLOBAL_ONBOARDING_STATE_LS);
    if (globalOnboardingState) {
      setCompleteOnboardingState(JSON.parse(globalOnboardingState));
    }
  }, []);

  return (
    <GlobalState.Provider
      value={{
        completeOnboardingState,
        onboardingProgressState,
        updateCompleteOnboardingState,
        updateProgressState,
      }}
    >
      <Box
        sx={{
          backgroundColor: BACKGROUND_GREY,
          height: '100vh',
          width: '100vw',
          backgroundImage: `url(${background})`,
      
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </Box>
    </GlobalState.Provider>
  );
};

export default MainContainer;
