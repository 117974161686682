import { Avatar, Box, Button, Modal, Typography } from '@mui/material';

import { DARK_BLUE, LIGHT_BLUE, BLUE, GREY, DARK_GREY } from '../design-constants';

type ModalProps = {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
};
const UpdatePreviousStateModal = ({ open, handleClose, handleConfirm }: ModalProps) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30vw',
            bgcolor: 'background.paper',
            border: DARK_BLUE,
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography sx={{ fontSize: '1.2rem', fontWeight: 500, textAlign: 'center' }}>
            Sure you want update a previous response?
          </Typography>
          <Typography sx={{ fontSize: '0.9rem', textAlign: 'center', color: DARK_GREY }}>
            Updating a previous response will erase subsequent progress
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '1rem',
              alignItems: 'center',
              marginTop: '1rem',
            }}
          >
            <Button variant="contained" onClick={handleConfirm} sx={{ backgroundColor: BLUE }} disableElevation={true}>
              Confirm
            </Button>
            <Button variant="outlined" onClick={handleClose} sx={{ borderColor: DARK_BLUE, color: DARK_BLUE }}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default UpdatePreviousStateModal;
