import { Box, Button, Paper, TextField, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import OtpInput from 'react-otp-input';
import { BLUE, DARK_BLUE, DARK_GREY, PURPLE } from '../design-constants';
import { useTheme } from '@mui/material/styles';
const Otp = ({
  timer,
  otp,
  handleResend,

  handleSubmitOtp,
  handleOtpInput,
  invalidOtp,
}: {
  timer: number;
  otp: string;
  invalidOtp: boolean;
  handleOtpInput: (value: string) => void;
  handleResend: () => void;
  handleSubmitOtp: () => void;
}) => {
  const theme = useTheme();
  const min_width_600 = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Paper
      elevation={3}
      sx={{
        backgroundColor: 'white',
        padding: '1.5rem',
        borderRadius: 2,
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        maxWidth: min_width_600 ? '30%' : '80%',
      }}
    >
      <Typography sx={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '1rem', textAlign: 'center' }}>Verify OTP</Typography>
      <Typography sx={{ fontSize: '1rem', marginBottom: '1rem' }}>
        An OTP has been sent to your registered contact number. <br></br>Please enter the 6-digit code to verify your identity
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: min_width_600 ? 'row' : 'column', gap: '1.5rem' }}>
        <OtpInput
          value={otp}
          onChange={(value) => handleOtpInput(value)}
          numInputs={6}
          renderSeparator={<span style={{ width: '0.8rem' }}></span>}
          renderInput={(props) => <input {...props} />}
          shouldAutoFocus={true}
          inputType="tel"
          inputStyle={{
            borderRadius: '0.5rem',
            width: '2.5rem',
            height: '2.5rem',
            fontSize: '1rem',
            color: 'black',
            fontWeight: '400',
            caretColor: DARK_GREY,
            border: `1px solid ${DARK_GREY}`,
            outline: 'none',
          }}
        />
        <Button
          variant="contained"
          onClick={handleSubmitOtp}
          sx={{
            backgroundColor: BLUE,
            textTransform: 'none',
          }}
          disableElevation={true}
        >
          Verify
        </Button>
      </Box>
      {invalidOtp && (
        <Typography sx={{ marginTop: '0.6rem', fontSize: '0.8rem', color: 'red', fontWeight: 550 }}>
          Invalid OTP
        </Typography>
      )}
      {!!timer && <Typography sx={{ marginTop: '0.6rem', fontSize: '0.8rem', fontWeight: 550 }}>00:{timer}</Typography>}
      {!timer && (
        <Box sx={{ marginTop: '1rem' }}>
          <Box sx={{ fontSize: '0.9rem', display: 'inline' }}>Didn't recieve OTP ? </Box>
          <Box
            onClick={handleResend}
            sx={{ fontSize: '0.9rem', display: 'inline', color: DARK_BLUE, cursor: 'pointer', fontWeight: 500 }}
          >
            Resend
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default Otp;
