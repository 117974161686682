import { Avatar, Box, IconButton, LinearProgress, Typography, useMediaQuery, useTheme } from '@mui/material';

import { DARK_BLUE, DARK_GREY, LIGHT_BLUE } from '../design-constants';
import { ChevronLeft } from '@mui/icons-material';

const HeaderBar = ({
  progress,
  onBackClick,
  pageTitle,
}: {
  progress: number;
  onBackClick: () => {};
  pageTitle: string;
}) => {
  const theme = useTheme();
  const min_width_600 = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '0.5rem',
          backgroundColor: 'white',

          marginTop: '1rem',
          padding: '0.5rem 1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: min_width_600 ? '40%' : '60%',
            }}
          >
            <IconButton size="medium" aria-label="back" onClick={onBackClick}>
              <ChevronLeft sx={{ fontSize: '2.5rem' }} />
            </IconButton>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column',
                width: '80%',
                gap: '0.3rem',
              }}
            >
              <Typography sx={{ textAlign: 'start', width: '100%' }}>Investor Profile Builder</Typography>
              <Typography
                sx={{ fontSize: '1rem', fontWeight: 600, lineHeight: 1.2, textAlign: 'start', width: '100%' }}
              >
                {pageTitle}
              </Typography>
              <LinearProgress variant="determinate" value={progress} sx={{ width: '100%' }} />
            </Box>
          </Box>
          <Typography
            sx={{ width: min_width_600 ? '30%' : '40%', fontSize:min_width_600? '0.8rem':"0.6rem", color: DARK_GREY, textAlign: 'right' }}
          >
            This will help us to advice you to appropriately keeping in mind your dependents and probable expenditure in
            upcoming years
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default HeaderBar;
