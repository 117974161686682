import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { onUserInput } from '../common/types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
type ChatOptionInputProps = {
  value: string;
  type: string;
  onChange: onUserInput;
  index: number[];
};
const ChatOptionDatePicker = ({ value, onChange, index }: ChatOptionInputProps) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ fontSize: '0.6rem' }}
          onChange={(newValue) => {
            if (newValue) {
              onChange(index, dayjs(newValue).toISOString(), true);
            }
          }}
          value={dayjs(value)}
        />
      </LocalizationProvider>
    </>
  );
};

export default ChatOptionDatePicker;
