import {
  BUTTON_INPUT_TYPE,
  BUTTON_MULTI_SELECT_INPUT_TYPE,
  DATE_INPUT,
  DROPDOWN_INPUT,
  NUMERIC_INPUT,
  TEXT_INPUT,
} from './constants';
import { answersObject, OnboardingStateNode, OnboardingStateSection } from './types';

export function handleUserInput(
  questionOptionType: string,
  questionOption: string[],
  selectedOption: string[],
  value: string,
  selected: boolean
): string[] {
  let result = [] as string[];
  switch (questionOptionType) {
    case BUTTON_INPUT_TYPE:
      if (selected) {
        result = [value];
      } else {
        result = [];
      }
      break;

    case BUTTON_MULTI_SELECT_INPUT_TYPE:
      if (selected) {
        result = [...selectedOption, value];
      } else {
        result = selectedOption.filter((element) => element !== value);
      }
      result = result.sort((a, b) => {
        return questionOption.indexOf(a) - questionOption.indexOf(b);
      });
      break;

    case DROPDOWN_INPUT:
      result = [value];
      break;

    case NUMERIC_INPUT:
      result = [value];
      break;

    case TEXT_INPUT:
      result = [value];
      break;

    case DATE_INPUT:
      const [date] = value.split('T');
      result = [date];
      break;

    default:
  }

  return result;
}

export function checkIfAllNodeQuestionsAnswered(nodeQuestions: OnboardingStateNode[]): boolean {
  // Iterate over the node questions
  for (let i = 0; i < nodeQuestions.length; i++) {
    const selectedOption = nodeQuestions[i].selectedOption;

    // Check if selectedOption is a string or array and is empty
    if (!selectedOption || selectedOption.length === 0) {
      return false; // Return early as we found an unanswered question
    }
  }

  return true;
}

export function erasePreviousState(deletedOnBoardingState: OnboardingStateSection[], globalState: answersObject) {
  for (let i = 0; i < deletedOnBoardingState.length; i++) {
    const node = deletedOnBoardingState[i];
    for (let j = 0; j < node.nodeQuestions.length; j++) {
      const questionId = node.nodeQuestions[j].questionId;
      globalState[questionId] = [];
    }
  }

  return globalState;
}
