import { Box, Button, Paper, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';

import { DARK_BLUE, PURPLE, BLUE } from '../design-constants';

const PhoneLogin = ({
  showTextBox,
  phoneNumber,
  userName,
  handleSendOtp,
  invalidPhoneNumber,
  handlePhoneNumberInput,
}: {
  showTextBox: boolean;
  phoneNumber?: string;
  userName?: string;
  invalidPhoneNumber: boolean;
  handleSendOtp: () => void;
  handlePhoneNumberInput: (value: string) => void;
}) => {
  const theme = useTheme();
  const min_width_600 = useMediaQuery(theme.breakpoints.up('sm'));
  const verifyPhoneMsg = !phoneNumber
    ? "We need to verify it's you before we can begin. Could you provide your phone number?"
    : "We need to verify it's you before we can begin.";
  return (
    <Paper
      elevation={3}
      sx={{
        backgroundColor: 'white',
        padding: '1.5rem',
        borderRadius: 2,
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        maxWidth: min_width_600 ? '30%' : '80%',
      }}
    >
      {userName && <Typography sx={{ fontSize: '1.5rem', textAlign: 'center'  }}>Hi {userName},</Typography>}
      <Typography sx={{ fontSize: '1.5rem', marginBottom: '1rem', textAlign: 'center' }}>
        Welcome to WealthGenie!
      </Typography>
      <Typography sx={{ fontSize: '1rem', marginBottom: '1rem', textAlign: 'center' }}>
        Your journey to informed and confident investing starts here.
      </Typography>
      <Typography sx={{ fontSize: '1rem', marginBottom: '1rem', textAlign: 'center' }}>{verifyPhoneMsg}</Typography>
      <Box sx={{ display: 'flex', flexDirection: min_width_600 ? 'row' : 'column', gap: '1.5rem', justifyContent: 'center', alignItems: 'center' }}>
        {showTextBox ? (
          <Typography sx={{ fontSize: '1.2rem', fontWeight: 500, textAlign: 'center' }}>{phoneNumber}</Typography>
        ) : (
          <TextField
            error={invalidPhoneNumber}
            value={phoneNumber}
            onChange={(event) => {
              handlePhoneNumberInput(event.target.value);
            }}
            label="Enter Phone number"
            variant="outlined"
          />
        )}

        <Button
          variant="contained"
          onClick={() => {
            handleSendOtp();
          }}
          sx={{
            backgroundColor: BLUE,
            textTransform: 'none',
          }}
          disableElevation={true}
        >
          Send OTP
        </Button>
      </Box>
    </Paper>
  );
};

export default PhoneLogin;
