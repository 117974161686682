export const BLUE='#0163BA'

export const PURPLE='#6c1fd3'

export const LIGHT_BLUE='#bad5ec'

export const DARK_BLUE='#1b4e93'

export const GREY ='#f5f5f5'

export const LIGHT_GREY='#fafafa'

export const DARK_GREY="#857f7f"

export const BACKGROUND_GREY='#ebedef'

export const WHITE = '#ffffff'
